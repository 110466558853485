<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col>
          <v-toolbar class="align-items-center" color="transparent" flat>
            <v-card-title class="pl-0">
              {{ $t("admin.customerpartcategory.list.title") }}
            </v-card-title>

            <v-spacer></v-spacer>

            <v-btn color="primary" to="create" disabled append text>
              Ny kunde vare kategori
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row align="start">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="categories"
            :hide-default-footer="true"
            sort-by="code"
            :items-per-page="-1"
            class="elevation-1"
            :search="search"
          >
            <template #[`item.profitFactor`]="{ item }">
              <v-edit-dialog
                :return-value.sync="item.profitFactor"
                persistent
                large
                save-text="Gem"
                cancel-text="Annuller"
                @save="save(item)"
              >
                {{ item.profitFactor }}%

                <template #input>
                  <v-text-field
                    v-model="item.profitFactor"
                    label="Ny værdi"
                    autofocus
                  />
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

var searchTimeout = null;

export default {
  name: "CustomerPartCategoryList",
  data() {
    return {
      categories: [],
      loading: false,
      search: '',
      headers: [
        { text: 'Kode', value: 'code', },
        { text: 'Titel', value: 'title', },
        { text: 'Profitfaktor', value: 'profitFactor', align: 'end' },
      ],
    };
  },

  mounted() {
    this.loading = true;

    this.$AuthService.api.get("adminpart/categories/customer")
      .then((response) => {
        this.categories = response.data.map(cat => ({
          ...cat,
          profitFactor: cat.profitFactor * 100
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    async save(category) {
      // Apply transform before sending update request
      const data = {
        ...category,
        profitFactor: category.profitFactor / 100
      };

      try {
        await this.$AuthService.api.patch(`adminpart/categories/customer/${category.id}`, data);

        this.$notifier.showSuccess({ message: 'Ændringen blev gemt' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Ændringen blev ikke gemt!' });
      }
    }
  }
};
</script>

<style>
.part-table.v-data-table tr {
  cursor: pointer;
}
</style>
